@import url(https://fonts.googleapis.com/css?family=Roboto:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300&display=swap);

.card1 {
    border-radius: 12px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    background-color: rgb(31, 31, 31);
    margin: 2rem auto;
    max-width: 95%;
    width: 350px;
}
body{
  background-color: black;
};

.emoji{
  max-width: 200px;
};
.cardheader {
  background-color: #3e3e3e;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.buttonarea {
  position: relative;
  padding: 10px;
}

.Eventlogo {
  padding: 10px;
}
/*# sourceMappingURL=home.css.map */
.cardheader {
  background-color: #3e3e3e;
  padding: 1rem;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.buttonarea {
  position: relative;
  padding: 10px;
}

body, html {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #303030;
}

.btn {
  margin: 10px;
}

.button {
  width: 200px;
  height: 50px;
  position: absolute;
  border: solid rgba(255, 255, 255, 0.3) 1px;
  background: transparent;
  cursor: pointer;
  transition: all 1s;
}

.button span {
  position: absolute;
  top: 15px;
  left: 60px;
  color: white;
  font-family: 'Roboto', sans-serif;
  opacity: 0.7;
}

.button .line {
  background: white;
  position: absolute;
  transition: none;
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 1;
}

.button .line:nth-child(1) {
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
  height: 1px;
  width: 20%;
  bottom: 0;
}

.button .line:nth-child(4) {
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  height: 1px;
  width: 20%;
  top: -1px;
  right: 0;
}

.button .line:nth-child(2) {
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
  height: 100%;
  width: 1px;
  bottom: 0;
  left: -1px;
}

.button .line:nth-child(5) {
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
  height: 100%;
  width: 1px;
  bottom: 0;
  right: -1px;
}

.button .line:nth-child(3) {
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  height: 1px;
  width: 100%;
  top: -1px;
  left: -1px;
}

.button .line:nth-child(6) {
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
  height: 1px;
  width: 100%;
  bottom: 0;
  right: -1px;
}

.button:hover > .line {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 0;
  transition: all 1s;
}

.button:hover > .line:nth-child(2), .button:hover > .line:nth-child(5) {
  transition-delay: 0.5s;
}

.button:hover > .line:nth-child(3), .button:hover > .line:nth-child(6) {
  transition-delay: 0.9s;
}

.button:hover {
  background: rgba(255, 255, 255, 0.03);
}
/*# sourceMappingURL=TBX.css.map */
.buttonarea {
  position: relative;
  padding: 10px;
}

body, html {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #303030;
}

.btn {
  margin: 10px;
}

.button {
  width: 200px;
  height: 50px;
  position: absolute;
  border: solid rgba(255, 255, 255, 0.3) 1px;
  background: transparent;
  cursor: pointer;
  transition: all 1s;
}

.button span {
  position: absolute;
  top: 15px;
  left: 60px;
  color: white;
  font-family: 'Roboto', sans-serif;
  opacity: 0.7;
}

.button .line {
  background: white;
  position: absolute;
  transition: none;
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 1;
}

.button .line:nth-child(1) {
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
  height: 1px;
  width: 20%;
  bottom: 0;
}

.button .line:nth-child(4) {
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  height: 1px;
  width: 20%;
  top: -1px;
  right: 0;
}

.button .line:nth-child(2) {
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
  height: 100%;
  width: 1px;
  bottom: 0;
  left: -1px;
}

.button .line:nth-child(5) {
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
  height: 100%;
  width: 1px;
  bottom: 0;
  right: -1px;
}

.button .line:nth-child(3) {
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  height: 1px;
  width: 100%;
  top: -1px;
  left: -1px;
}

.button .line:nth-child(6) {
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
  height: 1px;
  width: 100%;
  bottom: 0;
  right: -1px;
}

.button:hover > .line {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 0;
  transition: all 1s;
}

.button:hover > .line:nth-child(2), .button:hover > .line:nth-child(5) {
  transition-delay: 0.5s;
}

.button:hover > .line:nth-child(3), .button:hover > .line:nth-child(6) {
  transition-delay: 0.9s;
}

.button:hover {
  background: rgba(255, 255, 255, 0.03);
}
/*# sourceMappingURL=Secutec.css.map */
.cardheader {
  background-color: #3e3e3e;
  padding: 1rem;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.buttonarea {
  position: relative;
  padding: 10px;
}

body, html {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #303030;
}

.btn {
  margin: 10px;
}

.button {
  width: 200px;
  height: 50px;
  position: absolute;
  border: solid rgba(255, 255, 255, 0.3) 1px;
  background: transparent;
  cursor: pointer;
  transition: all 1s;
}

.button span {
  position: absolute;
  top: 15px;
  left: 60px;
  color: white;
  font-family: 'Roboto', sans-serif;
  opacity: 0.7;
}

.button .line {
  background: white;
  position: absolute;
  transition: none;
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 1;
}

.button .line:nth-child(1) {
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
  height: 1px;
  width: 20%;
  bottom: 0;
}

.button .line:nth-child(4) {
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  height: 1px;
  width: 20%;
  top: -1px;
  right: 0;
}

.button .line:nth-child(2) {
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
  height: 100%;
  width: 1px;
  bottom: 0;
  left: -1px;
}

.button .line:nth-child(5) {
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
  height: 100%;
  width: 1px;
  bottom: 0;
  right: -1px;
}

.button .line:nth-child(3) {
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  height: 1px;
  width: 100%;
  top: -1px;
  left: -1px;
}

.button .line:nth-child(6) {
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
  height: 1px;
  width: 100%;
  bottom: 0;
  right: -1px;
}

.button:hover > .line {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 0;
  transition: all 1s;
}

.button:hover > .line:nth-child(2), .button:hover > .line:nth-child(5) {
  transition-delay: 0.5s;
}

.button:hover > .line:nth-child(3), .button:hover > .line:nth-child(6) {
  transition-delay: 0.9s;
}

.button:hover {
  background: rgba(255, 255, 255, 0.03);
}
/*# sourceMappingURL=TBX.css.map */
