@import url("https://fonts.googleapis.com/css?family=Roboto:300&display=swap");
.cardheader {
  background-color: #3e3e3e;
  padding: 1rem;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.buttonarea {
  position: relative;
  padding: 10px;
}

body, html {
  position: relative;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #303030;
}

.btn {
  margin: 10px;
}

.button {
  width: 200px;
  height: 50px;
  position: absolute;
  border: solid rgba(255, 255, 255, 0.3) 1px;
  background: transparent;
  cursor: pointer;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.button span {
  position: absolute;
  top: 15px;
  left: 60px;
  color: white;
  font-family: 'Roboto', sans-serif;
  opacity: 0.7;
}

.button .line {
  background: white;
  position: absolute;
  -webkit-transition: none;
  transition: none;
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 1;
}

.button .line:nth-child(1) {
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
  height: 1px;
  width: 20%;
  bottom: 0;
}

.button .line:nth-child(4) {
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  height: 1px;
  width: 20%;
  top: -1px;
  right: 0;
}

.button .line:nth-child(2) {
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
  height: 100%;
  width: 1px;
  bottom: 0;
  left: -1px;
}

.button .line:nth-child(5) {
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
  height: 100%;
  width: 1px;
  bottom: 0;
  right: -1px;
}

.button .line:nth-child(3) {
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  height: 1px;
  width: 100%;
  top: -1px;
  left: -1px;
}

.button .line:nth-child(6) {
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
  height: 1px;
  width: 100%;
  bottom: 0;
  right: -1px;
}

.button:hover > .line {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 0;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.button:hover > .line:nth-child(2), .button:hover > .line:nth-child(5) {
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
}

.button:hover > .line:nth-child(3), .button:hover > .line:nth-child(6) {
  -webkit-transition-delay: 0.9s;
          transition-delay: 0.9s;
}

.button:hover {
  background: rgba(255, 255, 255, 0.03);
}
/*# sourceMappingURL=TBX.css.map */